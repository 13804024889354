import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://2628b5c8d50e6ebc36e5a65280a9bee2@o4504206617542656.ingest.us.sentry.io/4507700497547264",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: "50e3173",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});